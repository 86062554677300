import React from 'react'
import "../components/payments.css"

const Payments = () => {

  async function copyToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
      /* ✅ Copied successfully */
    } catch (e) {
      /* ❌ Failed to copy (insufficient permissions) */
    }
  }

  const writehora = () => {
    return (<>
        <div className='contenthora'>
            <h5>🕘 Horario de atención 🕘</h5>
            <div className='contentatencion'>
                <b>👇Atención UNICAMENTE dentro del horario👇</b>
            </div>
            <div>Lunes y viernes<br />9:30 a 11:30 - 17:00 a 20:00<br />
            </div>
            <div>Martes a jueves<br />9:30 a 13:00 - 17:00 a 20:00<br />
                <b>Sabados, domingos y feriados: CERRADO</b></div>
        </div>
    </>)
}

   const writedecember = () => {
    return (<>
      <div className='contenthora'>
        <h5>💵 Nuevo precio a partir de enero</h5>
        <div className='contentatencion'>
          <b>⚠️ $ 7000 a partir de enero 2025</b>
        </div>
        <div>A partir de enero de 2025 nos vemos obligados a realizar ajustes, tanto a nivel operativo como a nivel de precios.<br />
          Siempre tratando de mantener al máximo la relación precio/calidad. Y seguir manteniendo y agregando servers a la plataforma. Para mejorar velocidad, estabilidad y calidad<br />
          Por lo que nuestros costos se ven muy incrementados y nos obliga a hacer un poco más costoso el servicio, que pasará a costar <b>$ 7000/mes.</b><br />
          Esperamos que puedan entender la medida y continuar juntos por mucho tiempo más.
          🤝 Muchas gracias!</div>
      </div>
    </>)
  }

  const writeatencion = () => {
    return (<>
      <div className='contentatencion'>
        <b>☝️Atención UNICAMENTE dentro del horario☝️</b>
      </div>
    </>)
  }

  return (<>
    {writehora()}
    {writeatencion()}
    <hr />
     {writedecember()}
    <h1>Métodos de pago correo S2K</h1>
    <div className='contentprecio'><span className='divprecio'>Importe: 💵 $ 5.000,00 (cinco mil pesos)</span>puede transferirlo a cualquiera de los siguientes alias:</div>
{/*     <div className='contentprecio'><span className='divprecio'>Importe: 💵 $ 7.000,00 (siete mil pesos)</span>puede transferirlo a cualquiera de los siguientes alias:</div>
  */}   <div className='contentalias'>
      <div>💸 <span className='cuentas'>Lemon</span>: neura.norma.lemon<button className='boton' onClick={() => copyToClipboard("neura.norma.lemon")}>Copiar</button></div>
      <div>💸 <span className='cuentas'>Prex</span>: 27952878.prex<button className='boton' onClick={() => copyToClipboard("27952878.prex")}>Copiar</button></div>
      <div>💸 <span className='cuentas'>Uala</span>: enegraso.uala<button className='boton' onClick={() => copyToClipboard("enegraso.uala")}>Copiar</button></div>
      <div>💸 <span className='cuentas'>Belo</span>: fedeveloperbelo<button className='boton' onClick={() => copyToClipboard("fedebeloperbelo")}>Copiar</button></div>
      <div>💸 <span className='cuentas'>Personal</span>: fedeveloperppay<button className='boton' onClick={() => copyToClipboard("fedeveloperppay")}>Copiar</button></div>
      <div>💸 <span className='cuentas'>Claro</span>: fedevelopercpay<button className='boton' onClick={() => copyToClipboard("fedevolopercpay")}>Copiar</button></div>
      <div>💸 <span className='cuentas'>YPF</span>: fedevelopypf<button className='boton' onClick={() => copyToClipboard("fedevelopypf")}>Copiar</button></div>
      <div>💸 <span className='cuentas'>MercadoPago</span>: fedeveloper<button className='boton' onClick={() => copyToClipboard("fedeveloper")}>Copiar</button></div>
      <div>🏦 <span className='cuentas'>Naranja</span>: fedevelopernx<button className='boton' onClick={() => copyToClipboard("fedevelopernx")}>Copiar</button></div>
      <div>🏦 <span className='cuentas'>Provincia</span>: fedeveloperdni<button className='boton' onClick={() => copyToClipboard("fedeveloperdni")}>Copiar</button></div>
    </div>
    <div className='contentprecio'>Puede copiar el alias de su preferencia y luego pegarlo en su app de pago<br /><b>Debe enviar el comprobante una vez realizado el pago</b></div>
    <br />
    <div style={{ backgroundColor: "lightgray" }}>En efectivo 💵 $ 5.000,00 (cinco mil pesos) en <b>Rapipago</b> a cuenta <b>PREX: 10408748</b></div>
{/*     <div style={{ backgroundColor: "lightgray" }}>En efectivo 💵 $ 7.000,00 (siete mil pesos) en <b>Rapipago</b> a cuenta <b>PREX: 10408748</b></div>
 */}    <br />
    <div className='contenthora'>
      <div>Link de pago:<br />incluye comisiones de pasarela de pago</div>
      <div><b>Mercado Pago:</b><button className='boton' onClick={() => window.open("https://mpago.la/1k8gaNt")}>Pagar</button></div>
      <div>Presione le botón Pagar para ir a la pasarela de pago</div>
    </div>
    <br />
     {writedecember()}
<hr />
    {writehora()}
    {writeatencion()}
  </>)
}

export default Payments