import React, { useRef, useState } from 'react'
import "../components/payments.css"
import { REACT_APP_CLAVESITIO, REACT_APP_CLAVESECRETA } from '../store/consts/consts';
import Reaptcha from "reaptcha"
import Swal from 'sweetalert2';
import appsnew from "../images/01newdispo.jpg"
import appsfen from "../images/02newdispo.jpg"
import appsmed from "../images/mediafire.jpg"
import appscin from "../images/fenmed.jpg"
import ReactPlayer from "react-player";

const InstallApp = () => {

    const [term, setTerm] = useState('');
    const [text, setText] = useState('');
    const [errmessage, setErrMessage] = useState('por favor complete el captcha')

    const [captchaToken, setCaptchaToken] = useState(null);
    const captchaRef = useRef(null);

    const verify = () => {
        captchaRef.current.getResponse().then(res => {
            setCaptchaToken(res)
        })

    }


    async function copyToClipboard(text) {
        try {
            await navigator.clipboard.writeText(text);
            /* ✅ Copied successfully */
        } catch (e) {
            /* ❌ Failed to copy (insufficient permissions) */
        }
    }

    const writehora = () => {
        return (<>
            <div className='contenthora'>
                <h5>🕘 Horario de atención 🕘</h5>
                <div className='contentatencion'>
                    <b>👇Atención UNICAMENTE dentro del horario👇</b>
                </div>
                <div>Lunes y viernes<br />
                    9:30 a 11:30 - 17:00 a 20:00<br />
                </div>
                <div>Martes a jueves<br />
                    9:30 a 13:00 - 17:00 a 20:00<br />
                    <b>Sabados, domingos y feriados: CERRADO</b></div>
            </div>
        </>)
    }

    const writeatencion = () => {
        return (<>
            <div className='contentatencion'>
                <b>☝️Atención UNICAMENTE dentro del horario☝️</b>
            </div>
        </>)
    }

    const handleSubmit = (event) => {
        // Preventing the page from reloading
        event.preventDefault();

        // Do something 
        // alert("TOKEN Captcha", captchaToken, "captcharef", captchaRef);

        //
        captchaToken === null ? Swal.fire({
            title: "Atención " + errmessage,
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: `Error`,
            icon: "error",
        }) : window.open("http://mtd.llc/calibraciones.php?email=" + term, "_blank"); setCaptchaToken(null); setErrMessage('Debe refrescar esta página')

    }


    return (<>
        {writehora()}
        {writeatencion()}
        <div>
            <h3>Descargar app <div><img src={appsnew}></img></div> en cualquiera de los siguientes equipos:</h3>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                UNICAMENTE: Convertidor android (TV Box), tablets android, celulares android y emuladores Android para Windows (recomendado Nox Player)
            </div>
        </div>
        <div>
            Seguir los pasos a continuación:<br />
            <div className='showcase'>
                <div className='contentinstallapp'>
                    <b>a) (1) APP OFICIAL:</b> ir al navegador web 🌐 y escribe el siguiente link, en la barra de direcciones <button className='boton' onClick={() => window.open("http://bit.ly/multivision-play")}>👉 http://bit.ly/multivision-play 👈</button> v6.0.6<br />
                    (NO usar la barra de búsqueda).<br/>En <b>celu o tablet android</b>, solo <b>presiona el link/botón turquesa (haz tap)</b> con el dedo.<br />
                    <b>b)</b> En la página azul<br /><img src={appsmed} /><br />Aceptar la descarga de multivision.apk<br />
                    <b>c)</b> Al terminar de descargar, clic en abrir/open (para instalar) en mensaje emergente. <br />
                    Si el mensaje se borra (abrir/open) muy rápido, salga del navegador y valla a las apps del equipo, buscar file manager/archivos y alli la carpeta download y buscar el archivo multivision.apk <br />
                    (si tiene varios archivos llamados tv con números entre paréntesis, aquel con el numero mas grande será el último bajado).<br />
                    <b>d)</b> abre la app e inicia sesión<br />
                    <b>e)</b> A disfrutar!<br />
                    <a href="https://km210.com/apk/instalarentvbox.mp4" target="_blank" rel="noopener noreferrer" download><button className='boton'>Descargue Ejemplo en video</button></a>
                    <div className="formulario">
                        <ReactPlayer url="https://km210.com/apk/instalarentvbox.mp4"
                            type="video/mp4"
                            width='500px'
                            height='300px'
                            controls={true}
                        /></div>
                </div>
            </div>
            <hr />
            <div className='contentinstall'>
                <h1>SOLO: Si tiene problemas para visualizar el contenido de la app</h1>
                Chequear/actualizar servers en su wifi<br />
                Ingrese su usuario de la app y de clic/tap en chequear<br />
                Este paso intentará refrescar nuestros servers en su conexion de internet<br />
                (haga esto en su red wifi)
                <div className='formulario'>
                    <form onSubmit={handleSubmit}> {/* // ?email=ejemplo@tv.com */}
                        <label>Usuario:
                            <input
                                value={term}
                                onChange={(e) => setTerm(e.target.value)}
                                type="text"
                                placeholder="usuario@de.app"
                                className="input"
                                required></input></label>
                        <Reaptcha
                            sitekey={REACT_APP_CLAVESITIO}
                            ref={captchaRef}
                            onVerify={verify}
                        />
                        <button type="submit" className="btn-success">Chequear</button>
                    </form>
                </div>
            </div>
            <hr />

            <div className='contentinstall'>
                <b>a) (2) APP de RESPALDO:</b> ir al navegador web 🌐 y escribe el siguiente link, en la barra de direcciones <button className='boton' onClick={() => window.open("http://bit.ly/tv-app123")}>👉 http://bit.ly/tv-app123 👈</button> v3.0.3<br />
                (NO usar la barra de búsqueda).<br/>En <b>celu o tablet android</b>, solo <b>presiona el link/botón turquesa (haz tap)</b> con el dedo.<br />
                <b>b)</b> Aceptar la descarga de tv.apk<br />
                <b>c)</b> Al terminar de descargar, clic en abrir/open (para instalar) en mensaje emergente. <br />
                Si el mensaje se borra (abrir/open) muy rápido, salga del navegador y valla a las apps del equipo, buscar file manager/archivos y alli la carpeta download y buscar el archivo tv.apk <br />
                (si tiene varios archivos llamados tv con números entre paréntesis, aquel con el numero mas grande será el último bajado).<br />
                <b>d)</b> abre la app e inicia sesión<br />
                <b>e)</b> A disfrutar!<br />
            </div>
            <hr />
            <div className='contentinstall'>
                <h4>Si ninguna de las apps anteriores le funciona:</h4>
                <div><img src={appsfen}></img></div>
                <b>a) APP de RESPALDO:</b> ir al navegador web 🌐 y escribe el siguiente link, en la barra de direcciones <button className='boton' onClick={() => window.open("http://tvwave.tv/fenix.apk")}>👉 http://tvwave.tv/fenix.apk 👈</button> v3.0.5 <br />
                (NO usar la barra de búsqueda).<br/>En <b>celu o tablet android</b>, solo <b>presiona el link/botón turquesa (haz tap)</b> con el dedo.<br />
                <b>b)</b> Aceptar la descarga de fenix.apk<br />
                <b>c)</b> Al terminar de descargar, clic en abrir/open (para instalar) en mensaje emergente. <br />
                Si el mensaje se borra (abrir/open) muy rápido, salga del navegador y valla a las apps del equipo, buscar file manager/archivos y alli la carpeta download y buscar el archivo fenix.apk <br />
                (si tiene varios archivos llamados tv con números entre paréntesis, aquel con el numero mas grande será el último bajado).<br />
                <b>d)</b> abre la app e inicia sesión<br />
                <b>e)</b> A disfrutar!<br />
            </div>
            <hr />
            <div className='contentinstall'>
                <h4>Aclaracion segun zona</h4>
                Tenga a bien tener las 2 o 3 apps, por si una fallara.<br />
                <b>NOTA:</b> Solo ingrese sus datos, en una (a la vez) de las apps que instale<br />
                Además, si aún tiene en su dispositivo, <b>desinstale mund1al 📺 d1git4l</b><br />
                <b>Por último:</b>⚠️ cuando haya event0, les sugerimos ingresar al c4nal 20 o 30 minutos antes y no cambiarlo durante el evento para evitar saturación.⚠️
            </div>{/* 
            <hr />
            <div className='contentinstall'>
                <h4>Más contenido</h4>
                <b>a) (3)APP ADICIONAL c1n3 fen1x:</b> ir al navegador web 🌐 y escribe el siguiente link, en la barra de direcciones <button className='boton' onClick={() => window.open("http://bit.ly/cinefenix")}>👉 https://bit.ly/cinefenix 👈</button><br />
                (NO usar la barra de búsqueda) . En celu, solo presiona el link con el dedo.<br />
                <b>b)</b>En la página azul<br/><img src={appscin} /><br/> Aceptar la descarga de cin3fen1x.apk<br />
                <b>c)</b> Al terminar de descargar, clic en abrir/open (para instalar) en mensaje emergente. <br />
                Si el mensaje se borra (abrir/open) muy rápido, salga del navegador y valla a las apps del equipo, buscar file manager/archivos y alli la carpeta download y buscar el archivo cinefenix.apk <br />
                (si tiene varios archivos llamados tv con números entre paréntesis, aquel con el numero mas grande será el último bajado).<br />
                <b>d)</b> abre la app e inicia sesión<br />
                <b>e)</b> A disfrutar!<br />
                NOTA: Esta app consume más recursos de internet y dispositivo. En algunos convertidores genericos, podria fallar.
            </div> */}
            <hr />
            <div className='contentnota'>
                NOTA: <b>No nos hacemos responsables</b> por cualquier inconveniente *o daño al equipo* al instalar la app. Si usted no es avezado en el tema, le solicitamos que, *por favor contacte a su asesor/técnico IT de cabacera*, para poder recibir asesoramiento en los pasos de esta guia.
            </div>
        </div>
        <br />
        <div className='contentpdf'>
            Para <b>TELEVISORES</b> y dispositivos <b>AndroidTV / GoogleTV / Fire TV</b> descargue el siguiente  <a href="https://sib-2000.com.ar/docs/newtuto.pdf" target="_blank" rel="noopener noreferrer" download><button className='boton'>Documento PDF</button></a>
        </div>
        <br />
        {writehora()}
        {writeatencion()}
    </>)

}

export default InstallApp